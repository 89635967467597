import React, { Component } from 'react'
import i18next from 'i18next'
import { I18nextProvider, withTranslation } from 'react-i18next'

export function withTrans(WrappedComponent) {
  WrappedComponent = withTranslation()(WrappedComponent);

  return class extends Component {
    render() {
      // Configuration done here, because we have here access to this.props.location
      const supportedLanguages = ['hu', 'en', 'de'];
      let initLang;
      try {
        const pathname = this.props.location.pathname;
        initLang = pathname.substr(1, 2);
        if (!supportedLanguages.includes(initLang)) { initLang = 'hu'; }
      } catch (e) {
        initLang = 'hu';
      }
      i18next.init({
        fallbackLng: 'hu',
        lng: initLang,
        resources: {
          hu: {
            translations: require('../locales/hu/translations.json')
          },
          en: {
            translations: require('../locales/en/translations.json')
          },
          de: {
            translations: require('../locales/de/translations.json')
          }
        },
        ns: ['translations'],
        defaultNS: 'translations',
        returnObjects: true,
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
          escapeValue: false, // not needed for react!!
        },
        react: {
          wait: true,
        },
      });

      i18next.languages = supportedLanguages;

      return (
        <I18nextProvider i18n={i18next}>
          <WrappedComponent {...this.props} language={i18next.language} />
        </I18nextProvider>
      );
    }
  }
}