/**
 * This layout is provided by the gatsby-plugin-layout plugin. 
 * This way the layout won't be rerendered if the language is changed.
 */
import React from "react"
import PropTypes from "prop-types"

import SiteNavbar from "../navbar/navbar"
import { withTrans } from "../../i18n/with-translation"
import "./layout.css"

const Layout = ({ children, t }) => {

  return (
    <>
      <SiteNavbar />
      <div className="content-div">
        <main>{children}</main>
      </div>
      <footer className="footer-content">
        © {new Date().getFullYear()}, {t('footer.copyright')}
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
