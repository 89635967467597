import React, { useState } from "react"

import { Navbar } from 'react-bootstrap'
import { Nav } from 'react-bootstrap'
import { NavDropdown } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { Link as RLink, animateScroll as scroll } from "react-scroll"
import { Link } from "gatsby"
import "./navbar.css"

import HungaryFlag from "../../images/icons/hungary-flag-xs.jpg"
import GermanFlag from "../../images/icons/germany-flag-xs.jpg"
import UkFlag from "../../images/icons/united-kingdom-flag-xs.jpg"

const SiteNavbar = () => {
    const { t, i18n } = useTranslation();
    useState({
        language: 'hu'
    });

    function handleChange(event, lang) {
        i18n.changeLanguage(lang);
        document.getElementById("basic-nav-dropdown").click(); // Close the dropdown
    }

    function scrollToTop() {
        scroll.scrollToTop();
    };

    /**
     * Using <Link> of react-scroll thirdparty with the styles of Nav.Link for smooth scrolling
     */
    return (
        <div className="navbar-div">

            <Navbar expand="lg" className="navbar-content">
                <Navbar.Brand className="brand" role="button" onClick={() => scrollToTop()}>{t('navbar.title')}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="justify-content-end nav-intern">
                        <Nav.Item>
                            <RLink
                                className="link-text"
                                role="button"
                                activeClass="active"
                                to="intro"
                                spy={true}
                                smooth={true}
                                offset={-29}
                                duration={1000}
                            >{t('navbar.about')}</RLink>
                        </Nav.Item>
                        <Nav.Item>
                            <RLink
                                className="link-text"
                                role="button"
                                activeClass="active"
                                to="apartment-services"
                                spy={true}
                                smooth={true}
                                offset={-29}
                                duration={1000}
                            >{t('navbar.services')}</RLink>
                        </Nav.Item>
                        <Nav.Item>
                            <RLink
                                className="link-text"
                                role="button"
                                activeClass="active"
                                to="gallery"
                                spy={true}
                                smooth={true}
                                offset={-28}
                                duration={1000}
                            >{t('navbar.gallery')}</RLink>
                        </Nav.Item>
                        <Nav.Item>
                            <RLink
                                className="link-text"
                                role="button"
                                activeClass="active"
                                to="contacts"
                                spy={true}
                                smooth={true}
                                offset={-27}
                                duration={1000}
                            >{t('navbar.contact')}</RLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavDropdown title={"Languages"} id="basic-nav-dropdown">
                                <Link className={"dropdown-item " + (i18n.language === 'hu'?"active":"")} role="button" onClick={(event) => handleChange("hu")} to={"/"}><img className="flag language-label" src={HungaryFlag} alt="Magyar zászló" /><span className="language-label">Magyar</span></Link>
                                <Link className={"dropdown-item " + (i18n.language === 'en'?"active":"")} role="button" onClick={(eventKey) => handleChange("en")} to={"/en"}><img className="flag language-label" src={UkFlag} alt="English flag" /><span className="language-label">English</span></Link>
                                <Link className={"dropdown-item " + (i18n.language === 'de'?"active":"")} role="button" onClick={(eventKey) => handleChange("de")} to={"/de"}><img className="flag language-label" src={GermanFlag} alt="Deutsche Fahne" /><span className="language-label">Deutsch</span></Link>
                            </NavDropdown>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default SiteNavbar;
